import { useEffect, useState } from "react";
import { haveValue } from "../../Utils/helpers";
import WebsiteLogo from "./WebsiteLogo";

const WebsiteIcon = (props) => {

    const { listing, service, website } = props;
    const [websiteLink, setWebsiteLink] = useState(null);

    useEffect(() => {
        if (haveValue(listing?.listingUrl)) {
            setWebsiteLink(listing?.listingUrl);
        }
    }, [listing]);

    return (
        <>
            <WebsiteLogo onMouseEnter={props?.onMouseEnter} onMouseLeave={props?.onMouseLeave} service={service} website={website} websiteLink={websiteLink} />
        </>
    );
}
export default WebsiteIcon;