import { useEffect, useState } from "react";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";

const Sharer = (props) => {

    const [shareUrl, setShareUrl] = useState("");
    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        setShareUrl(props?.shareUrl);
        setTitle(props?.title);
        setImage(props?.image);
    }, [props]);

    return (
        <div className='theme-sharer'>
            <div className="theme-sharer-container">
                <div className="theme-sharer-network">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={title}
                        className="theme-sharer-network__share-button"
                    >
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </div>

                <div className="theme-sharer-network">
                    <TwitterShareButton
                        url={shareUrl}
                        title={title}
                        className="theme-sharer-network__share-button"
                    >
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </div>

                <div className="theme-sharer-network">
                    <TelegramShareButton
                        url={shareUrl}
                        title={title}
                        className="theme-sharer-network__share-button"
                    >
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                </div>

                <div className="theme-sharer-network">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                        className="theme-sharer-network__share-button"
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </div>

                <div className="theme-sharer-network">
                    <LinkedinShareButton url={shareUrl} className="theme-sharer-network__share-button">
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </div>

                <div className="theme-sharer-network">
                    <EmailShareButton
                        url={shareUrl}
                        subject={title}
                        body="body"
                        className="theme-sharer-network__share-button"
                    >
                        <EmailIcon size={32} round />
                    </EmailShareButton>
                </div>

            </div>
        </div>
    );
};

export default Sharer;