import React from "react";
import Scanner from "../../Components/Scanner";
const Home = () => {
    return (
        <>
            <Scanner />
        </>
    );
}

export default Home;