export const endpoint = {
  public: {
    CONFIGURATIONS: "/public/configurations",
    COUNTRIES: "/public/countries",
    STATES: "/public/states",
    CITIES: "/public/cities",
    SCAN: "/public/scan",
    RUN_SCAN: "/public/run-scan",
    SCAN_DETAILS: "/public/scan-details",
    SOCIAL_DETAILS: "/public/social-details",
    BUSINESS_CATEGORIES: "/public/business-categories",
    MATCH_BUSINESS_CATEGORIES: "/public/match-business-categories",
  },
  auth: {
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    GOOGLE_LOGIN_URL: "/auth/google",
    GOOGLE_LOGIN_CALLBACK: "/auth/google/callback",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    ACTIVATE_ACCOUNT: "/auth/activate-account",
  },
  user: {
  },
  media: {
  },
  admin: {
  },
};
