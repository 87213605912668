import { useEffect, useState } from "react";
import { haveValue } from "../../Utils/helpers";
import WebsiteIcon from "./WebsiteIcon";

const ListingWebsiteBox = (props) => {

    const { listing } = props;

    const [websiteLink, setWebsiteLink] = useState(null);
    const [matchStatus, setMatchStatus] = useState("not-found");
    const [matchState, setMatchState] = useState("not-found");

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [mobileNo, setMobileNo] = useState("");

    useEffect(() => {
        if (haveValue(listing?.listingUrl)) {
            setWebsiteLink(listing?.listingUrl);
        }
        if (haveValue(listing?.accuracy)) {
            let accuracy = listing?.accuracy.accuracy;
            let matchStateLabel = "";
            let matchStatusLabel = "";
            if (accuracy.nameMatched.toString() === "true" && accuracy.phoneMatched.toString() === "true" && accuracy.addressMatched.toString() === "true") {
                matchStateLabel = "Everything Matched";
                matchStatusLabel = "fullMatched";
            } else if (accuracy.nameMatched.toString() === "true" && accuracy.phoneMatched.toString() === "true" && accuracy.addressMatched.toString() === "false") {
                matchStateLabel = "Address Not Matched";
                matchStatusLabel = "partialMatched";
            } else if (accuracy.nameMatched.toString() === "true" && accuracy.phoneMatched.toString() === "false" && accuracy.addressMatched.toString() === "true") {
                matchStateLabel = "Phone no. Not Matched";
                matchStatusLabel = "partialMatched";
            } else if (accuracy.nameMatched.toString() === "true" && accuracy.phoneMatched.toString() === "false" && accuracy.addressMatched.toString() === "false") {
                matchStateLabel = "Address and Phone no. Not Matched";
                matchStatusLabel = "partialMatched";
            } else {
                matchStateLabel = "Nothing Matched";
                matchStatusLabel = "notMatched";
            }
            setMatchState(matchStateLabel);
            setMatchStatus(matchStatusLabel);

            if (haveValue(listing?.accuracy?.dataFound)) {
                let dataFound = listing?.accuracy?.dataFound;
                if (dataFound?.name) {
                    setName(dataFound?.name);
                }
                if (dataFound?.fullAddress) {
                    setAddress(dataFound?.fullAddress);
                } else {
                    let addresses: any = [];
                    if (dataFound?.businessAddress) {
                        addresses.push(dataFound?.businessAddress);
                    }
                    if (dataFound?.city) {
                        addresses.push(dataFound?.city);
                    }
                    if (dataFound?.stateCode) {
                        addresses.push(dataFound?.stateCode);
                    }
                    if (dataFound?.zipcode) {
                        addresses.push(dataFound?.zipcode);
                    }
                    if (dataFound?.countryCode) {
                        addresses.push(dataFound?.countryCode);
                    }
                    addresses = addresses.join(", ");
                    setAddress(addresses);
                }
                if (dataFound?.phones) {
                    setMobileNo(dataFound?.phones[0]);
                }
            }

        }
    }, [listing]);

    return (
        <>
            <WebsiteIcon onMouseEnter={props?.onMouseEnter} onMouseLeave={props?.onMouseLeave} service={props?.service} website={props?.website} listing={props?.listing} />
        </>
    );
}
export default ListingWebsiteBox;