import { createSlice } from '@reduxjs/toolkit'
import i18n from 'i18next';


const initialState = {
    toasts: [],
    loadingText: '',
    showingLoading: false
}

//loadingState | 0, no, 1 = yes, 2 = hide

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showSuccessToast(state: any, action) {
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        type: 'success',
                        message: action.payload
                    }
                ],
            }
        },
        showErrorToast(state: any, action) {
            return {
                ...state,
                toasts: [
                    ...state.toasts,
                    {
                        type: 'error',
                        message: action.payload
                    }
                ],
            }
        },
        hideToast(state: any, action) {
            let index = action.payload;
            return {
                ...state,
                toasts: [
                    ...state.toasts.slice(0, index),
                    ...state.toasts.slice(index + 1)
                ]
            }
        },
        hideAllToasts(state: any, action) {
            return {
                ...state,
                toasts: []
            }
        },
        showLoadingToast(state: any, action) {
            if (action.payload == null) {
                action.payload = i18n.t("pleaseWait");
            }
            return {
                ...state,
                loadingText: action.payload,
                showingLoading: true
            }
        },
        hideLoadingToast(state: any, action) {
            return {
                ...state,
                loadingText: '',
                showingLoading: false
            }
        }
    }
});

export const { showSuccessToast, showErrorToast, showLoadingToast, hideToast, hideAllToasts, hideLoadingToast } = toastSlice.actions;

export default toastSlice.reducer;