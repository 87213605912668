import { configureStore } from '@reduxjs/toolkit'

import toastReducer from './slices/toastSlice';
import configurationsReducer from './slices/configurationsSlice';

const store = configureStore({
    reducer: {
        toast: toastReducer,
        configurations: configurationsReducer,
    }
});

function handleStateChange() {
    const state = store.getState();
}

const state = store.getState();
// console.log('Current state:', state);
store.subscribe(handleStateChange);

export default store;