import { endpoint } from "../Config/endpoints";
import { sendRequest } from "./http-service";

export const getConfigurations = () => {
  return sendRequest(endpoint.public.CONFIGURATIONS);
};

export const getCountries = (payload: any = {}) => {
  return sendRequest(endpoint.public.COUNTRIES, "GET", payload);
};

export const getStates = (payload: any = {}) => {
  return sendRequest(endpoint.public.STATES, "GET", payload);
};

export const getCities = (payload: any = {}) => {
  return sendRequest(endpoint.public.CITIES, "GET", payload);
};

export const scan = (payload: any = {}) => {
  return sendRequest(endpoint.public.SCAN, "POST", payload);
};

export const scanDetails = (payload: any = {}) => {
  return sendRequest(endpoint.public.SCAN_DETAILS, "GET", payload);
};

export const runScan = (payload: any = {}) => {
  return sendRequest(endpoint.public.RUN_SCAN, "GET", payload);
};

export const getSocialDetails = (payload: any = {}) => {
  return sendRequest(endpoint.public.SOCIAL_DETAILS, "POST", payload);
};

export const getBusinessCategories = (payload: any = {}) => {
  return sendRequest(endpoint.public.BUSINESS_CATEGORIES, "GET", payload);
};

export const matchBusinessCategories = (payload: any = {}) => {
  return sendRequest(endpoint.public.MATCH_BUSINESS_CATEGORIES, "GET", payload);
};