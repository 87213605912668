import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { hideLoadingToast, showErrorToast, showLoadingToast } from "../../Redux/slices/toastSlice";
import { scan } from "../../Services/public-service";
import { haveValue } from "../../Utils/helpers";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const Scanner = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [formFields, setFormFields]: any = useState({});
    const [submitScan, setSubmitScan]: any = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { reportId } = useParams();

    const handleStep1 = (values) => {
        setFormFields({
            ...formFields,
            ...values
        });
        setActiveTab(2);
    }

    const handleStep2 = (values) => {
        setFormFields({
            ...formFields,
            ...values
        });
        setSubmitScan(true);
    }

    useEffect(() => {
        if (submitScan && haveValue(formFields?.businessName)) {
            initialScan();
        }
    }, [formFields, submitScan]);

    const initialScan = () => {
        setSubmitScan(false);
        dispatch(showLoadingToast(null));
        scan(formFields).then((res: any) => {
            navigate("/home/" + res?.data?._id);
        }).catch((err: any) => {
            dispatch(showErrorToast(err?.message));
        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    useEffect(() => {
        if (haveValue(reportId)) {
            setActiveTab(3);
        } else {
            setActiveTab(1);
        }
    }, [reportId]);

    const createAndDownloadPdf = async (reportId) => {
        dispatch(showLoadingToast("Generating Pdf"));

        let clientWidth: any = document?.body?.clientWidth;
        let clientHeight: any = document?.body?.clientHeight;

        let htmlString: any = document.documentElement.innerHTML;
        let iframe: any = document.createElement("iframe");
        iframe.style.visibility = "hidden";
        iframe.style.width = `${clientWidth}px`;
        iframe.style.height = `${clientHeight}px`;
        // iframe.style.height = "100px";
        document.body.appendChild(iframe);
        let iframedoc: any = iframe?.contentDocument || iframe?.contentWindow?.document;
        iframedoc.body.innerHTML = htmlString;

        let canvas = await html2canvas(iframedoc.body, {});

        // Convert the iframe into a PNG image using canvas.  
        let imgData = canvas.toDataURL("image/png");

        // Create a PDF document and add the image as a page.
        const doc = new JsPDF({
            unit: "px",
            format: [clientWidth, clientHeight],
        });
        doc.addImage(imgData, "PNG", 0, 0, clientWidth, clientHeight);
        doc.save(`${reportId}.pdf`);
        //hide loader
        dispatch(hideLoadingToast(null));
        document.body.removeChild(iframe); 
    }

    return (
        <>
            <div className='main_wrapper  bg container-fluid '>
                <div className='logo d-flex justify-content-center '>
                    <img onClick={(e) => navigate('/home')} src='/images/logo.png' />
                </div>
                <div className='tabs_section scanner'>
                    <ul className="nav scanner-stepper justify-content-center align-items-center nav-pills" id="pills-tab" role="tablist">

                        <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 1 ? 'completed' : ''} ${activeTab === 1 ? 'active' : ''}`} role="presentation">
                            <button className={`nav-link nocursorhover nav_size rounded-5`} id="pills-home-tab" type="button" role="tab" aria-controls="pills-home" aria-selected="true">1</button>
                            <span className='actv'>Nature of Business</span>
                        </li>

                        <li className={`line bar nav-item ${activeTab > 2 ? 'completed' : ''} ${activeTab === 2 ? 'active' : ''}`}></li>

                        <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 2 ? 'completed' : ''} ${activeTab === 2 ? 'active' : ''}`} role="presentation">
                            <button className={`nav-link nocursorhover nav_size  rounded-5`} id="pills-profile-tab" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">2</button>
                            <span className='actv'>Business Details</span>
                        </li>

                        <li className={`line bar nav-item ${activeTab > 3 ? 'completed' : ''} ${activeTab === 3 ? 'active' : ''}`}></li>

                        <li className={`nav-item step d-flex flex-row align-items-center ${activeTab > 3 ? 'completed' : ''} ${activeTab === 3 ? 'active' : ''}`} role="presentation">
                            <button className={`nav-link nocursorhover nav_size rounded-5`} id="pills-contact-tab" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">3</button>
                            <span className='actv'>Scan Results</span>
                        </li>

                    </ul>
                    <div className="tab-content " id="pills-tabContent">
                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <Step1 formFields={formFields} onSubmit={handleStep1} />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <Step2 formFields={formFields} onSubmit={handleStep2} />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <Step3 createAndDownloadPdf={createAndDownloadPdf} reportId={reportId} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Scanner;