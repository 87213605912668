import React from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { BsShareFill } from 'react-icons/bs'
import { IoLocationOutline, IoLocationSharp } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { handleFormikChange, handleFormikSubmit } from '../../Utils/helpers'
import FormField from '../FormFields/FormField'
import * as yup from "yup";
import { useFormik } from "formik";
import { format } from 'url'
import TelephoneField from '../FormFields/TelephoneField'

const Step1 = (props) => {

    const dispatch = useDispatch();

    const validationSchema = yup.object({
        businessType: yup.string().required("Business Type is required"),
        name: yup.string().required("Name is required"),
        phone: yup.string().required("Phone is required"),
        email: yup.string().email("Enter a valid Email Address").required("Email is required"),
    });

    const form = useFormik({
        initialValues: {
            businessType: "single-location",
            name: "",
            phone: "",
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const changeBusinessType = (businessType) => {
        handleFormikChange(businessType, form, "businessType");
    }

    return (
        <>
            <div className='main_div_wrapper first align-items-center  max-w row'>
                <Form onSubmit={(e) => handleFormikSubmit(e, form)}>
                    <div className='main_bg_div'>
                        <h3 className='main_title'>What kind of business do you run?</h3>
                        <div className='main_div_row'>
                            <div className=" w-100 first_tabs ">
                                <div className="nav flex-row justify-content-between w-100 nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button onClick={(e) => changeBusinessType("single-location")} className="nav-link  active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                        <div className='main_bordered_div'>
                                            <div className='bluebox_icon'>

                                                <img src='/images/first.png' />
                                            </div>
                                            <p className='m-0 blue fs-5'>Single Location<br></br> Business</p>
                                        </div>
                                    </button>
                                    <button onClick={(e) => changeBusinessType("multiple-locations")} className="nav-link " id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"><div className='main_bordered_div'>
                                        <div className='bluebox_icon'>
                                            <img src='/images/second.png' />
                                        </div>
                                        <p className='m-0 blue fs-5'>Multiple Location <br></br>Business</p>
                                    </div></button>
                                    <button onClick={(e) => changeBusinessType("marketing-agency")} className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"><div className='main_bordered_div'>
                                        <div className='bluebox_icon'>

                                            <img src='/images/third.png' />
                                        </div>
                                        <p className='m-0 blue fs-5'> Marketing<br></br> Agency</p>
                                    </div>
                                    </button>
                                </div>
                                {/* <div className="tab-content " id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"><img className='img-fluid' src='/images/rafiki.png' alt='img1' /></div>
                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><img className='img-fluid' src='/images/rafiki2.png' alt='img2' /></div>
                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab"><img className='img-fluid' src='/images/rafiki3.png' alt='img3' /></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='simple_form'>
                        {/* <h3 >Enter Details</h3> */}
                        <div>
                            <Row>
                                <Col md={12}>
                                    <FormField
                                        id="name"
                                        name="name"
                                        icon="fa fa-user-o"
                                        value={form.values.name}
                                        onChange={(e) => handleFormikChange(e, form)}
                                        showError={form.touched.name}
                                        errorText={form.errors.name}
                                        placeholder={"Enter Name"}
                                    />
                                </Col>
                                <Col md={6}>
                                    <TelephoneField
                                        id="phone"
                                        name="phone"
                                        fieldType="phone"

                                        value={form.values.phone}
                                        showError={form.touched.phone}
                                        onChange={(phone) =>
                                            handleFormikChange(phone, form, "phone")
                                        }
                                        errorText={form.errors.phone}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormField
                                        id="email"
                                        name="email"
                                        icon="fa fa-envelope-o"
                                        fieldType="email"
                                        value={form.values.email}
                                        onChange={(e) => handleFormikChange(e, form)}
                                        showError={form.touched.email}
                                        errorText={form.errors.email}
                                        placeholder={"Enter Email"}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <button className='btn btn-primary center_button d-flex align-items-center col-space'><span>Check Status Now </span><i className="fa fa-angle-right" aria-hidden="true"></i></button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Step1;