import { ThreeDots } from 'react-loader-spinner';
import React from 'react';

const Dotloader = () => {
    return <>
        <div className="dots_loader">
            <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#0d6efd"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
            />
        </div>
    </>
}
export default Dotloader;