import React, { useEffect, useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { handleFormikChange, handleFormikSubmit, haveValue, parseSocialHandle, underScoredToNormal } from '../../Utils/helpers'
import FormField from '../FormFields/FormField'
import * as yup from "yup";
import { useFormik } from "formik";
import { getBusinessCategories, getCountries, getSocialDetails, getStates, matchBusinessCategories } from '../../Services/public-service'
import * as parseGooglePlace from 'parse-google-place';
import FormFieldSelect from '../FormFields/FormFieldSelect'
import Autocomplete from "react-google-autocomplete";
import { hideLoadingToast, showLoadingToast } from '../../Redux/slices/toastSlice'
import FormFieldSelectSearch from '../FormFields/FormFieldSelectSearch'

const Step2 = (props) => {

    const { formFields } = props;

    const [countries, setCountries]: any = useState([]);
    const [states, setStates]: any = useState([]);
    const [defaultBusinessCats, setDefaultBusinessCats]: any = useState([]);

    const dispatch = useDispatch();

    const validationSchema = yup.object({
        businessName: yup.string().required("Business Name is required"),
        businessCategory: yup.string().required("Business Category is required"),
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
        businessAddress: yup.string().required("Business Address is required"),
        zipcode: yup.string().required("Zipcode is required"),
        businessPhone: yup.string().required("Business Phone is required"),
        facebookHandle: yup.string(),
        instagramHandle: yup.string(),
        linkedInHandle: yup.string(),
        twitterHandle: yup.string(),
    });

    const form = useFormik({
        initialValues: {
            businessName: "",
            website: "",
            businessCategory: "",
            country: "",
            state: "",
            city: "",
            businessAddress: "",
            zipcode: "",
            businessPhone: "",
            facebookHandle: "",
            instagramHandle: "",
            linkedInHandle: "",
            twitterHandle: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.onSubmit(values);
        },
    });

    const loadCountries = () => {
        getCountries()
            .then((res: any) => {
                let countriesData: any = [];
                for (let country of res.data) {
                    countriesData.push({
                        _id: country?.isoCode,
                        name: country?.name,
                    });
                }
                setCountries(countriesData);
            })
            .catch((error) => {

            });
    };

    const loadStates = (countryCode) => {
        getStates({
            countryCode
        })
            .then((res: any) => {
                let statesData: any = [];
                for (let state of res.data) {
                    statesData.push({
                        _id: state.isoCode,
                        name: state.name,
                    });
                }
                setStates(statesData);
            })
            .catch((error) => {

            });
    };

    const handleCountryChange = (countryCode: any) => {
        loadStates(countryCode);
        handleFormikChange(countryCode, form, "country")
    }

    useEffect(() => {
        loadCountries();
    }, []);

    const fetchSocialDetails = (websiteUrl) => {
        dispatch(showLoadingToast("Checking Social Details"));
        getSocialDetails({
            domain: websiteUrl
        }).then((res: any) => {
            let data = res?.data;

            // if (haveValue(data?.phones) && data?.phones.length > 0) {
            //     let phone = data?.phones[0];
            //     if (haveValue(phone?.phoneNumber)) {
            //         if (!haveValue(form?.values?.businessPhone)) {
            //             handleFormikChange(phone?.phoneNumber, form, "businessPhone");
            //         }
            //     }    
            // }

            if (haveValue(data?.socials)) {
                if (haveValue(data?.socials?.facebook)) {
                    if (!haveValue(form?.values?.facebookHandle)) {
                        handleFormikChange(parseSocialHandle(data?.socials?.facebook, "facebook"), form, "facebookHandle");
                    }else{
                        handleFormikChange("", form, "facebookHandle");
                    }
                }else{
                    handleFormikChange("", form, "facebookHandle");
                }

                if (haveValue(data?.socials?.instagram)) {
                    if (!haveValue(form?.values?.instagramHandle)) {
                        handleFormikChange(parseSocialHandle(data?.socials?.instagram, "instagram"), form, "instagramHandle");
                    }else{
                        handleFormikChange("", form, "instagramHandle");
                    }
                }else{
                    handleFormikChange("", form, "instagramHandle");
                }

                if (haveValue(data?.socials?.linkedIn)) {
                    if (!haveValue(form?.values?.linkedInHandle)) {
                        handleFormikChange(parseSocialHandle(data?.socials?.linkedIn, "linkedIn"), form, "linkedInHandle");
                    }else{
                        handleFormikChange("", form, "linkedInHandle");
                    }
                }else{
                    handleFormikChange("", form, "linkedInHandle");
                }

                if (haveValue(data?.socials?.twitter)) {
                    if (!haveValue(form?.values?.twitterHandle)) {
                        handleFormikChange(parseSocialHandle(data?.socials?.twitter, "twitter"), form, "twitterHandle");
                    }else{
                        handleFormikChange("", form, "twitterHandle");
                    }
                }else{
                    handleFormikChange("", form, "twitterHandle");
                }
                
            }

        }).catch((err) => {

        }).finally(() => {
            dispatch(hideLoadingToast(null));
        });
    }

    const onPlaceSelect = (place, ref) => {
        console.log(place);
        let address = parseGooglePlace(place);

        if (!haveValue(address.address)) {
            if (haveValue(place?.vicinity)) {
                address.address = place?.vicinity;
                address.address = address.address.split(',');
                address.address.pop();
                address.address = address.address.join(', ');
            }
        }

        handleFormikChange(place?.formatted_phone_number, form, "businessPhone");

        handleFormikChange(address?.address, form, "businessAddress");
        handleFormikChange(address?.city, form, "city");
        handleFormikChange(address?.stateShort, form, "state");
        handleFormikChange(address?.countryShort, form, "country");

        handleFormikChange(address?.zipCode, form, "zipcode");

        if (haveValue(address?.countryShort)) {
            loadStates(address?.countryShort);
        }

        setTimeout((e) => {
            let input: any = document.querySelector("#businessNameField input");
            if (input != null) {
                input.value = place?.name;
                handleFormikChange(place?.name, form, "businessName");
            }
        }, 500);

        if (haveValue(place?.website)) {
            fetchSocialDetails(place?.website);
            handleFormikChange(place?.website, form, "website");
        }

        if (haveValue(place.types)) {

            let businessTypes: any = [];

            if (place.types.length > 0) {
                for (let businessType of place.types) {
                    businessTypes.push(underScoredToNormal(businessType));
                }
            }

            if (businessTypes.length > 0) {
                matchBusinessCategories({
                    items: businessTypes
                }).then((res: any) => {
                    let categories: any = [];
                    let data = res?.data;
                    let matchedItem: any = null;
                    if (data.length > 0) {
                        for (let item of data) {
                            categories.push({
                                value: item?.name,
                                label: item?.name
                            });
                            if (data.length === 1) {
                                matchedItem = item;
                            }
                        }
                    }

                    setDefaultBusinessCats(categories);
                    setTimeout((e) => {
                        let businessType = "";
                        if (matchedItem !== null) {
                            businessType = matchedItem.name;
                        }
                        handleFormikChange(underScoredToNormal(businessType), form, "businessCategory");
                    }, 500);

                }).catch((err: any) => {

                });
            }
        }
    }

    const loadBusinessCategories = (
        inputValue: string,
        callback: (options: any) => void
    ) => {
        getBusinessCategories({
            q: inputValue
        }).then((res: any) => {
            let categories: any = [];
            let data = res?.data;
            if (data.length > 0) {
                for (let item of data) {
                    categories.push({
                        value: item?.name,
                        label: item?.name
                    });
                }
            }
            callback(categories);
        }).catch((err: any) => {
            callback([]);
        });
    };

    // console.log(form);

    return (
        <>
            <div className='align-items-center second'>


                {
                    haveValue(formFields?.name) ? <>
                        <Form onSubmit={(e) => handleFormikSubmit(e, form)}>
                            <div className='wh box mb-3'>
                                <div>
                                    <h3 className='card-title-head'>Local</h3>
                                </div>
                                <Row >
                                    <Col md={6}>
                                        <div className="theme-forminput " id="businessNameField">
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </span>
                                                <Autocomplete
                                                    apiKey={"AIzaSyAvMGmHLuzC6Q2gR16oFuevYREnUQDmxJs"}
                                                    onPlaceSelected={(place, ref) => {
                                                        onPlaceSelect(place, ref);
                                                    }}
                                                    options={{
                                                        types: [],
                                                        fields: ["address_components", "formatted_phone_number", "name", "vicinity", "website", "type"]
                                                    }}
                                                    className="form-control"
                                                    placeholder='Enter Business Name'
                                                    defaultValue={form.values.businessName}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <FormFieldSelectSearch
                                            id="businessCategory"
                                            name="businessCategory"
                                            icon="fa fa-building"
                                            label={"Business Category"}
                                            value={form.values.businessCategory}
                                            onChange={(e) => handleFormikChange(e, form, "businessCategory")}
                                            showError={form.touched.businessCategory}
                                            errorText={form.errors.businessCategory}
                                            placeholder={"Enter Business Category"}
                                            loadOptions={loadBusinessCategories}
                                            defaultOptions={defaultBusinessCats}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <FormFieldSelect
                                            id="country"
                                            name="country"
                                            icon="fa fa-globe"
                                            label={"Country"}
                                            value={form.values.country}
                                            onChange={(e) => handleCountryChange(e?.target?.value)}
                                            showError={form.touched.country}
                                            errorText={form.errors.country}
                                            placeholder={"Select Country"}
                                            options={countries}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormFieldSelect
                                            id="state"
                                            name="state"
                                            icon="fa fa-map-marker"
                                            label={"State"}
                                            value={form.values.state}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.state}
                                            errorText={form.errors.state}
                                            placeholder={"Select State"}
                                            options={states}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormField
                                            id="city"
                                            name="city"
                                            icon="fa fa-map-pin"
                                            label={"City"}
                                            value={form.values.city}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.city}
                                            errorText={form.errors.city}
                                            placeholder={"Enter City"}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <FormField
                                            id="businessAddress"
                                            name="businessAddress"
                                            icon="fa fa-map"
                                            label={"Business Address"}
                                            value={form.values.businessAddress}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.businessAddress}
                                            errorText={form.errors.businessAddress}
                                            placeholder={"Enter Business Address"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormField
                                            id="zipcode"
                                            name="zipcode"
                                            icon="fa fa-location-arrow"
                                            label={"Zipcode"}
                                            value={form.values.zipcode}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.zipcode}
                                            errorText={form.errors.zipcode}
                                            placeholder={"Enter Zipcode"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormField
                                            id="businessPhone"
                                            name="businessPhone"
                                            icon="fa fa-phone"
                                            label={"Business Phone"}
                                            value={form.values.businessPhone}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.businessPhone}
                                            errorText={form.errors.businessPhone}
                                            placeholder={"Enter Business Phone"}
                                        />
                                    </Col>
                                    {/* <div className='horizontal_line'></div> */}
                                </Row>
                            </div>
                            <div className='wh box'>
                                <div>
                                    <h3 className='card-title-head'>Social <span className='small'>(Optional)</span></h3>
                                </div>
                                <Row className="wh">
                                    <Col md={6}>
                                        <FormField
                                            id="facebookHandle"
                                            name="facebookHandle"
                                            icon="fa fa-facebook-square"
                                            label={"Facebook Handle"}
                                            value={form.values.facebookHandle}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.facebookHandle}
                                            errorText={form.errors.facebookHandle}
                                            placeholder={"Enter Facebook Handle"}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormField
                                            id="instagramHandle"
                                            name="instagramHandle"
                                            icon="fa fa-instagram"
                                            label={"Instagram Handle"}
                                            value={form.values.instagramHandle}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.instagramHandle}
                                            errorText={form.errors.instagramHandle}
                                            placeholder={"Enter Instagram Handle"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="d-none">
                                        <FormField
                                            id="linkedInHandle"
                                            name="linkedInHandle"
                                            icon="fa fa-linkedin"
                                            label={"LinkedIn Handle"}
                                            value={form.values.linkedInHandle}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.linkedInHandle}
                                            errorText={form.errors.linkedInHandle}
                                            placeholder={"Enter LinkedIn Handle"}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <FormField
                                            id="twitterHandle"
                                            name="twitterHandle"
                                            icon="fa fa-twitter"
                                            label={"Twitter Handle"}
                                            value={form.values.twitterHandle}
                                            onChange={(e) => handleFormikChange(e, form)}
                                            showError={form.touched.twitterHandle}
                                            errorText={form.errors.twitterHandle}
                                            placeholder={"Enter Twitter Handle"}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className='step-2-btnbar'>
                                <button className='btn btn-primary mt-4 center_button d-flex align-items-center col-space'><span>Check Status Now </span><i className="fa fa-angle-right" aria-hidden="true"></i></button>
                            </div>
                        </Form>
                    </> : <>
                        <p className='form-noresults'>Please complete Step 1 first.</p>
                    </>
                }
            </div>
        </>
    )
}

export default Step2;