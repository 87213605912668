import { Spin } from "antd";
const MainLoader = () => {

    return (
        <>
            <div className="main-loader">
                <Spin />
            </div>
        </>
    );
};

export default MainLoader;
