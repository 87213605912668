import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const FormField = (props: any) => {
  const {
    label,
    errorText,
    fieldType,
    disabled = false,
    readOnly = false,
    showError = false,
    icon=""
  } = props;

  const [type, setType] = useState("text");

  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordField, setIsPasswordField] = useState(false);
  const [haveError, setHaveError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
    setIsPasswordField(fieldType === "password");

    if (isPasswordField) {
      if (showPassword) {
        setType("text");
      } else {
        setType("password");
      }
    } else {
      setType(fieldType ?? "text");
    }
  }, [props, showPassword]);

  return (
    <>
      <Form.Group
        className={`theme-forminput ${haveError ? "erroractive" : ""}`}
      >
        {/* <Form.Label>{label}</Form.Label> */}
        <InputGroup>
        <InputGroup.Text id="basic-addon1"> <i className={icon} aria-hidden="true"></i></InputGroup.Text>
            <Form.Control {...props} type={type} />
            {isPasswordField ? (
              <>
                <InputGroup.Text
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </InputGroup.Text>
              </>
            ) : null}
        </InputGroup>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default FormField;
