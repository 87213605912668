import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";

const TelephoneField = (props: any) => {
  
  const {
    label = "",
    errorText = "",
    value = null,
    onChange = () => {},
    showError = false
  } = props;

  const [haveError, setHaveError] = useState(false);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  return (
    <>
      <Form.Group
        className={`theme-forminput ${haveError ? "erroractive" : ""}`}
        controlId="telephone"
      >
        {/* <Form.Label>{label}</Form.Label> */}
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="US"
          value={value}
          onChange={onChange} 
          className="form-control"
        />
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </Form.Group>
    </>
  );
};

export default TelephoneField;
