import { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import SiteLayout from "./Components/Layout/SiteLayout";
import Home from "./Pages/Site/Home";

function AppRouter() {
  const history = useNavigate();

  useEffect(() => {
    document.body.setAttribute("data-page", window?.location?.pathname);
  }, [history]);

  return (
    <>
      <Routes>

        <Route element={<SiteLayout />}>
          <Route path="/home" element={<Home />} />
          <Route path="/home/:reportId" element={<Home />} />
        </Route>

        <Route path="/" element={<Navigate to="/home" replace={true} />} />
      </Routes>
    </>
  );
}

export default AppRouter;
