import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from 'antd';
import { haveValue } from "../../Utils/helpers";
import { hideToast } from "../../Redux/slices/toastSlice";


const Toasts = () => {

    const toastState = useSelector((state: any) => state.toast);
    const dispatch = useDispatch();

    const [messageApi, contextHolder] = message.useMessage({
        prefixCls: 'app-toast',
    });

    useEffect(() => {
        if (haveValue(toastState.toasts) && toastState?.toasts?.length > 0) {
            let lastIndex = (toastState.toasts.length - 1);
            let latestToast = toastState.toasts[lastIndex];
            messageApi.open({
                type: latestToast.type,
                content: latestToast.message
            });
            dispatch(hideToast(lastIndex));
        }
    }, [toastState]);

    useEffect(() => {
        if (haveValue(toastState.showingLoading)) {
            if (toastState.showingLoading) {
                messageApi.open({
                    type: 'loading',
                    content: toastState.loadingText,
                    duration: 0,
                    key: 'loadingToast'
                });
            } else {
                messageApi.destroy('loadingToast');
            }
        }
    }, [toastState]);

    return (
        <>
            {contextHolder}
        </>
    );
};

export default Toasts;
