import moment from "moment";
import * as Joi from "joi-browser";
import config from "./../Config";

export const haveValue = (val: any) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const validateJOIProperty = (schema: any, name: string, value: any) => {
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const validateJOIFormField = (formFields: any, schema: any) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData: any = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};

export const withBackendUrl = (path: any, defaultPath = null) => {
  if (typeof path === "object") {
    let src = URL.createObjectURL(path);
    return src;
  } else {
    if (haveValue(path)) {
      path = path?.toString();
      if (path?.startsWith("http://") || path?.startsWith("https://")) {
        return path;
      } else {
        if (path.startsWith("/")) {
          path = path.substring(1);
        }
        return config.BACKEND_URL + "/" + path;
      }
    } else {
      return "";
    }
  }
};

export const toLowerCase = (data: any) => {
  return data.toString().toLowerCase();
};

export const toUpperCase = (data: any) => {
  return data.toString().toUpperCase();
};

export const makeSureObject = (data: any) => {
  if (!haveValue(data)) {
    data = {};
  }
  return data;
};

export const cloneObject = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const printTime = (timeData: any) => {
  return moment(timeData).format("ll LT");
};

export const printAmount = (amount: any) => {
  return "$" + amount;
};

export const printNumber = (number: any) => {
  if (haveValue(number)) {
    if (number !== "-") {
      number = number.toLocaleString();
    }
  }
  return number;
};

export const initialPaginationData = () => {
  return {
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  };
};

export const objectToFormData = (
  obj: any,
  form: any = null,
  namespace: any = null
) => {
  let fd = form || new FormData();
  let formKey: any = null;
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + "[" + property + "]";
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof Blob) &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Array)
      ) {
        objectToFormData(obj[property], fd, property);
      } else if (obj[property] instanceof Array) {
        for (var i = 0; i < obj[property].length; i++) {
          fd.append(formKey + "[]", obj[property][i]);
        }
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const isInputEvent = (event: any) => {
  if (typeof event === "object") {
    if (event.hasOwnProperty("target")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const handleFormikChange = (
  event: any,
  formik: any,
  fieldName: any = null
) => {
  if (isInputEvent(event)) {
    let target = event.target;
    fieldName = target?.name;
    formik?.handleChange(event);
  } else {
    formik?.setFieldValue(fieldName, event);
  }
  setTimeout((e) => {
    formik?.setFieldTouched(fieldName, true);
  }, 300);
};

export const handleFormikSubmit = (e, formik: any) => {
  e?.preventDefault();
  e?.stopPropagation();
  let fieldsToMarkTouched = {};
  const fields = Object.keys(formik?.initialValues);
  for (let field of fields) {
    fieldsToMarkTouched[field] = true;
  }
  formik?.handleSubmit();
  setTimeout((e) => {
    formik?.setTouched(fieldsToMarkTouched, true);
  }, 300);
};

export const getColorAsPerGrade = (grade) => {
  switch (grade?.label) {
    case 'A+':
      return '#4fde6b';
      break;
    case 'A':
      return '#4fde6b';
      break;
    case 'B+':
      return '#FF675E';
      break;
    case 'B':
      return '#FFD43A';
      break;
    case 'C+':
      return '#FFD43A';
      break;
    case 'C':
      return '#FFD43A';
      break;
    case 'F':
      return '#FF675E';
      break;
  }
  return '#DFDFDF';
}

export const getClassNameFromGrade = (grade) => {
  if (haveValue(grade)) {
    grade = grade.toString().toLowerCase();
    grade = grade.replace(/\+/g, '1');
  }
  return grade;
}

export const getScanService = (website, service) => {
  let scanDetails = website.scanDetails;
  if (scanDetails == null) {
    scanDetails = {};
  }
  scanDetails = JSON.parse(JSON.stringify(scanDetails));
  let serviceData = scanDetails[service];
  if (haveValue(serviceData)) {
    return serviceData;
  }
  return null;
}

export const parseSocialHandle = (url, platform: any = "") => {
  if (haveValue(url)) {
    url = url.toString();
    url = url.replace(/\/$/, '');
  }
  url = url.split("/");
  return url[url.length - 1];
}

export const underScoredToNormal = (txt: any) => {
  if (haveValue(txt)) {
    txt = txt.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }
  return txt;
}