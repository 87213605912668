import { createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfigurations } from "../Redux/slices/configurationsSlice";
import { showErrorToast } from "../Redux/slices/toastSlice";
import { getConfigurations } from "../Services/public-service";
import usePersistedState from "./usePersistedState";


const AppContext = createContext({});

export function AppProvider({ children }: any) {

  const dispatch = useDispatch();

  const [darkMode, setDarkMode] = usePersistedState("darkMode", false);

  const loadAppConfigurations = () => {
    getConfigurations().then((res: any) => {
      dispatch(updateConfigurations(res?.data));
    }).catch((err: any) => {
      dispatch(showErrorToast("Failed to connect to Server"));
    });
  }

  return (
    <AppContext.Provider value={{ darkMode, setDarkMode, loadAppConfigurations }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
