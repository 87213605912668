import React from "react";
import { Spin } from 'antd';

const ContentLoader = () => {

    return (
        <>
            <div className="content-loader">
                <Spin className="my-5"/>
            </div>
        </>
    );
};

export default ContentLoader;
