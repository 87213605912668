import React, { useEffect, useState } from 'react'
import { Col, Row, Form, ProgressBar, Table, Dropdown } from 'react-bootstrap';
import { AiOutlineShareAlt, AiOutlineHeart, AiOutlineRise, AiOutlineUser, AiFillCopy, AiOutlineStar, AiOutlineUserAdd, AiFillAmazonCircle, AiOutlineDashboard, AiOutlineUserSwitch } from 'react-icons/ai'
import { BiCopy, BiUserX } from 'react-icons/bi'
import { PieChart, Pie, Tooltip } from 'recharts';
import { RxCheck, RxCross2 } from 'react-icons/rx'
import { BsFacebook, BsInstagram, BsLinkedin, BsPinterest, BsFillEyeFill, BsTwitter, BsPinMap, BsYelp, BsFan, BsGlobe, BsGraphUp, BsInfo, BsInfoCircle, BsSearch, BsVolumeUp } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { getClassNameFromGrade, getColorAsPerGrade, getScanService, haveValue, printNumber } from '../../Utils/helpers';
import ContentLoader from '../Loader/ContentLoader';
import DotsLoader from '../Loader/BoxLoader';
import { runScan, scanDetails } from '../../Services/public-service';
import { useDispatch } from 'react-redux';
import { showErrorToast, showSuccessToast } from '../../Redux/slices/toastSlice';
import Slider from "react-slick";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WebsiteIcon from './WebsiteIcon';
import { JobConstants, ScanConstants } from '../../Utils/constants';
import BarLoader from "react-spinners/BarLoader";
import WebsiteLogo from './WebsiteLogo';
import StarReview from '../Reviewstar/StarReview';
import Sharer from '../Sharer';
import ListingWebsiteBox from './ListingWebsiteBox';
import ListingWebsiteDetails from './ListingWebsiteDetails';

const Step3 = (props) => {

    const { reportId } = props;

    const dispatch = useDispatch();
    const [reportData, setReportData]: any = useState({});
    const [reportLoading, setReportLoading] = useState(true);
    const [listingUrl, setListingUrl] = useState('-');
    const [reportUrl, setReportUrl] = useState('-');

    const [listingDetails, setListingDetails]: any = useState(null);
    const [boxCss, setBoxCss]: any = useState({});

    useEffect(() => {
        if (reportId != null) {
            sendScanRun();
            loadScanDetails();
        }
    }, [reportId]);

    const sendScanRun = () => {
        runScan({
            id: reportId
        }).then((res: any) => {
            let reportDetails = res?.data;
            if (reportDetails.status !== "completed") {
                setTimeout((e) => {
                    sendScanRun();
                }, 6000);
            }
        }).catch((err) => {

        });
    }

    const loadScanDetails = (showLoaders = true) => {
        if (showLoaders) {
            setReportLoading(true);
        }
        scanDetails({
            id: reportId
        }).then((res: any) => {
            let reportDetails = res?.data;
            setReportData(reportDetails);
            if (showLoaders) {
                setReportLoading(false);
            }
            if (reportDetails.status !== "completed") {
                setTimeout((e) => {
                    loadScanDetails(false);
                }, 3000);
            }
        }).catch((err) => {
            if (showLoaders) {
                setReportLoading(false);
            }
            dispatch(showErrorToast("Report not Found"));
        });
    }

    useEffect(() => {
        let listingUrlTemp = "-";
        if (haveValue(reportData?.website)) {
            listingUrlTemp = reportData?.website;
        } else {
            if (haveValue(reportData?.websites) && reportData?.websites.length > 0) {
                for (let website of reportData?.websites) {
                    let data = getScanService(website, ScanConstants.SERVICES.listing);
                    if (data !== null) {
                        if (data.status === ScanConstants.WEBSITE_STATUS.exactFound) {
                            if (listingUrlTemp === '-') {
                                listingUrlTemp = data.listingUrl;
                                break;
                            }
                        }
                    }
                }
                if (listingUrlTemp === '-') {
                    for (let website of reportData?.websites) {
                        let data = getScanService(website, ScanConstants.SERVICES.listing);
                        if (data !== null) {
                            if (data.status === ScanConstants.WEBSITE_STATUS.invalidFound) {
                                if (listingUrlTemp === '-') {
                                    listingUrlTemp = data.listingUrl;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        setReportUrl(window.location.href);
        setListingUrl(listingUrlTemp);
    }, [reportData]);

    const onCopy = () => {
        dispatch(showSuccessToast("Copied to Clipboard"));
    }

    const downloadPdf = () => {
        props?.createAndDownloadPdf(reportId).then((res) => {
            //nothing
        }).catch((err) => {
            console.log(err);
            //nothing
        });
    }

    const fixErrors = () => {
        let fixErrorUrl = "https://apexmediasol.com/contact/";
        window.location.replace(fixErrorUrl);
    }

    const onShare = () => {

    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 13,
        slidesToScroll: 1
    };

    const showListingDetailsBox = (data, e) => {
        console.log("mouse enter");
        let cssToApply = {
            left: e?.clientX + "px",
            top: (e?.clientY + 25) + "px",
        };
        setBoxCss(cssToApply);
        setListingDetails(data);
    }

    const hideListingDetailsBox = (data) => {
        console.log("mouse leave");
        setListingDetails(null);
    }

    console.log(listingDetails);

    return (
        <>

            <div className='container-fluid  wrp-shadow'>
                {
                    haveValue(reportId) ? <>
                        {
                            reportLoading ? <>
                                <ContentLoader />
                            </> : <>
                                {
                                    haveValue(reportData?._id) ? <>

                                        {
                                            reportData?.status === 'pending' ? <>
                                                <div className="full-data-loader d-none">
                                                    <BarLoader
                                                        color="#0086FF"
                                                        width={100}
                                                    />
                                                </div>
                                            </> : null
                                        }

                                        <div>
                                            <Row className='justify-content-between m-0'>
                                                <Col md={12} className='px-0'>
                                                    <div className='d-flex flex-column align-items-center pe-3'>
                                                        <Row className='align-items-center m-0 borderround w-100 wh shadow p-2 '>
                                                            <Col md={12} className="listing-info">
                                                                <h1>{reportData?.businessName}</h1>
                                                                <p>{reportData?.businessAddress}, {reportData?.city}, {reportData?.stateCode} {reportData?.zipcode}, {reportData?.countryCode} <span className='ms-5' >{reportData?.businessPhone}</span></p>
                                                                {
                                                                    listingUrl != '-' ? <>
                                                                        <a href={listingUrl} className="listing-link" target="_blank">{listingUrl}</a>
                                                                    </> : null
                                                                }
                                                            </Col>
                                                            <Col md={12} className='d-flex flex-wrap align-items-center col-space py-1'>
                                                                <Dropdown className='sharer-dropdown'>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        <AiOutlineShareAlt className='cursorHover' onClick={onShare} size={25} />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Sharer title="Apex Media Solution - Scan Tool" shareUrl={listingUrl} />
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <Form.Control
                                                                    className='form_width'
                                                                    type="text"
                                                                    id="inputPassword5"
                                                                    placeholder={reportUrl}
                                                                    value={reportUrl}
                                                                />
                                                                <button className='btn outline mt-2 mt-md-0 btn-outline-light'>
                                                                    <CopyToClipboard text={reportUrl} onCopy={onCopy}>
                                                                        <BiCopy size={22} color='#0d6efd' />
                                                                    </CopyToClipboard>
                                                                </button>
                                                                <button onClick={fixErrors} className='btn mt-2 mt-md-0 btn-primary'>
                                                                    Fix errors
                                                                </button>
                                                                <button onClick={downloadPdf} className='btn btn-outline-primary mt-2 mt-md-0 t-p '>
                                                                    Download PDF
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                        <Row className='shadow wh borderround mx-0 mt-3 p-2 h-100'>
                                                            <div className='d-flex'>
                                                                <Row className='mx-0'>
                                                                    <Col md={12} className='rounded-5 p-1 heart_icon'>
                                                                        <div className='d-flex align-items-center pt-1'>
                                                                            <span className='ic-radius lightred me-2'><AiOutlineHeart size={16} /> </span> &nbsp;
                                                                            <h5 className='m-0 w-100'>
                                                                                <div className='txt-with-loader'>
                                                                                    <span className='title'>Your Overall Score</span>
                                                                                    {
                                                                                        reportData?.status === 'pending' ? <>
                                                                                            <ContentLoader />
                                                                                        </> : null}
                                                                                </div>
                                                                            </h5>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={3} className='justify-content-center my-4 py-2 align-items-center d-flex'>
                                                                        <div className='yellow_round_box'>
                                                                            <PieChart width={400} height={400}>
                                                                                <Pie
                                                                                    dataKey="value"
                                                                                    isAnimationActive={true}
                                                                                    data={[
                                                                                        { name: 'Accuracy', value: 100, fill: getColorAsPerGrade(reportData?.overall?.grade) },
                                                                                    ]}
                                                                                    cx="50%"
                                                                                    cy="50%"
                                                                                    outerRadius={100}
                                                                                    innerRadius={60}
                                                                                />
                                                                                <Tooltip />
                                                                            </PieChart>

                                                                            <div className='inside_chart'>
                                                                                <h1 className='chart_txt'>{reportData?.overall?.grade?.label}</h1>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={9} className="my-4">
                                                                        <div className='d-flex flex-column gy-2 py-2 mt-3 left_side_progress'>
                                                                            <Form.Label className='mb-0'>Local Visibility Score ({reportData?.localVisibility?.grade?.value}/100)</Form.Label>
                                                                            <ProgressBar className={`${getClassNameFromGrade(reportData?.localVisibility?.grade?.label)}_progress -ve mb-1`} now={reportData?.localVisibility?.grade?.value} label={reportData?.localVisibility?.grade?.label} />
                                                                            <Form.Label className='mb-0'>Voice search optimization score ({reportData?.voice?.grade?.value}/100)</Form.Label>
                                                                            <ProgressBar className={`${getClassNameFromGrade(reportData?.voice?.grade?.label)}_progress -ve mb-1`} now={reportData?.voice?.grade?.value} label={reportData?.voice?.grade?.label} />
                                                                            <Form.Label className='mb-0'>Reviews visibility score ({reportData?.review?.grade?.value}/100)</Form.Label>
                                                                            <ProgressBar className={`${getClassNameFromGrade(reportData?.review?.grade?.label)}_progress -ve mb-1`} now={reportData?.review?.grade?.value} label={reportData?.review?.grade?.label} />
                                                                            <Form.Label className='mb-0'>Social visibility score ({reportData?.social?.grade?.value}/100)</Form.Label>
                                                                            <ProgressBar className={`${getClassNameFromGrade(reportData?.social?.grade?.label)}_progress -ve mb-1`} now={reportData?.social?.grade?.value} label={reportData?.social?.grade?.label} />
                                                                            {/* <Form.Label className='mb-0'>Search visibility score ({reportData?.search?.grade?.value}/100)</Form.Label>
                                                                            <ProgressBar className={`${getClassNameFromGrade(reportData?.search?.grade?.label)}_progress -ve mb-1`} now={reportData?.search?.grade?.value} label={reportData?.search?.grade?.label} /> */}
                                                                        </div>
                                                                    </Col>
                                                                    <Col md={12} className='text_bottom ps-5'>
                                                                        Get an overview of your brand's online visibility, including search, local, social media, and reviews. It's the perfect way to get a holistic understanding of your brand's online presence.
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col md={6} className='wh d-none shadow borderround'>
                                                    <Row className=' table_big '>
                                                        <Col md={12} className=' p-2 '>
                                                            <div className='d-flex align-items-center pt-1'>
                                                                <span className='ic-radius lightblue me-2'><AiOutlineRise size={16} /></span>&nbsp;
                                                                <h5 className='m-0 w-100'>
                                                                    <div className='txt-with-loader'>
                                                                        <span className='title'>Your Performance against similar businesses</span>
                                                                        {
                                                                            reportData?.overall?.jobStatus?.search === JobConstants.pending ? <>
                                                                                <ContentLoader />
                                                                            </> : null
                                                                        }
                                                                    </div>
                                                                </h5>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Table className='table_bar brdsrnone businessesTables table-custom ' hover responsive>
                                                                <thead>
                                                                    <tr className='nowrp'>
                                                                        <th>BUSINESS NAME</th>
                                                                        <th>NAME ACCURACY</th>
                                                                        <th>ADDRESS ACCURACY</th>
                                                                        <th>PHONE ACCURACY</th>
                                                                        <th>SCORE</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        haveValue(reportData?.search?.business) ? <>
                                                                            {
                                                                                reportData?.search?.business.map((business, index) => {
                                                                                    return <>
                                                                                        <tr className={`mb-2 businessNameSearchRankRow ${business.myBusiness.toString() === "true" ? 'my-business' : ''}`} key={index}>
                                                                                            <td>
                                                                                                <div className={`d-flex col-space businessNameSearchRank`}>
                                                                                                    {
                                                                                                        business.myBusiness.toString() === "true" ? <>
                                                                                                            <span className='badge badge-primary'>You</span>
                                                                                                        </> : <></>
                                                                                                    }
                                                                                                    {/* <img className="fix-img" src='/images/prfrm1.png' />  */}
                                                                                                    <small>{business.name}</small>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td><ProgressBar now={business?.overall?.nameAccracy?.percentage} label={business?.overall?.nameAccracy?.percentage + "%"} /></td>
                                                                                            <td><ProgressBar now={business?.overall?.addressAccracy?.percentage} label={business?.overall?.addressAccracy?.percentage + "%"} /></td>
                                                                                            <td><ProgressBar now={business?.overall?.phoneAccracy?.percentage} label={business?.overall?.phoneAccracy?.percentage + "%"} /></td>
                                                                                            <td><b>{business?.localVisibility?.grade?.label}</b></td>
                                                                                        </tr>
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </> : null
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <div className='shadow wh mt-3 borderround'>
                                                <Row className='d-flex justify-content-center px-4 p-3 wrp-shadow'>
                                                    <Col md={8} className='p-2'>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='ic-radius lightpurple me-2'><AiOutlineDashboard size={16} /></span>&nbsp;
                                                            <h5 className='m-0 w-100'>
                                                                <div className='txt-with-loader'>
                                                                    <span className='title'>Local Visibility Score</span>
                                                                    {
                                                                        reportData?.overall?.jobStatus?.listing === JobConstants.pending ? <>
                                                                            <ContentLoader />
                                                                        </> : null
                                                                    }
                                                                </div>
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <p className='my-3'>Track your brand's online presence on Google, Bing, and prominent citations.  With this information, you can optimize your local SEO efforts and improve your brand's visibility.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className=' justify-content-md-end justify-content-center d-flex'>
                                                        <div className={`small_rounded_score ${getClassNameFromGrade(reportData?.localVisibility?.grade?.label)}`}>
                                                            <h1>{reportData?.localVisibility?.grade?.label}</h1>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Row className='justify-content-around py-3'>
                                                            <Col xxl={3} xl={4}>
                                                                <div className='yellow_round_box'>
                                                                    <PieChart width={400} height={400}>
                                                                        <Pie
                                                                            dataKey="value"
                                                                            isAnimationActive={true}
                                                                            data={[
                                                                                { name: 'Accurate', value: parseFloat(reportData?.localVisibility?.validPercentage), fill: getColorAsPerGrade(reportData?.localVisibility?.grade) },
                                                                                { name: 'Not Accurate/Found', value: (100 - parseFloat(reportData?.localVisibility?.validPercentage)), fill: '#dfdfdf' },
                                                                            ]}
                                                                            cx="50%"
                                                                            cy="50%"
                                                                            outerRadius={120}
                                                                            innerRadius={80}
                                                                        />
                                                                        <Tooltip />
                                                                    </PieChart>
                                                                    <div className='inside_chart mt-3'>
                                                                        <h1 className='chart_txt'>{reportData?.localVisibility?.validPercentage}%</h1>
                                                                        <p className='text-center'>Accuracy</p>
                                                                    </div>
                                                                </div>
                                                                <p className='mt-5 ps-5'>As on 44 directories available</p>
                                                            </Col>
                                                            <Col xxl={2} xl={8}>
                                                                <div className='d-flex justify-content-center flex-column'>
                                                                    <div className='shadow p-3'>
                                                                        <p>Incorrect Listings</p>
                                                                        <h1>{reportLoading ? <DotsLoader /> : reportData?.localVisibility?.invalidPercentage}%</h1>
                                                                    </div>
                                                                    <div className='shadow mt-2 p-3 d-none'>
                                                                        <p>Missing Listings</p>
                                                                        <h1>{reportData?.localVisibility?.notFoundPercentage}%</h1>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={7} xl={12}>

                                                                {
                                                                    haveValue(listingDetails) ? <>
                                                                        <ListingWebsiteDetails style={boxCss} website={listingDetails?.website} listing={listingDetails?.data} />
                                                                    </> : null
                                                                }

                                                                <div className='border_div mt-2 mt-md-0'>
                                                                    <div className='bg-light p-2 bb'><p className='px-4 m-0'>Found with Inaccurate Information</p></div>
                                                                    <div className='slick web-slick bb'>
                                                                        <Slider className='py-2' {...settings}>
                                                                            {
                                                                                reportData.websites.map((website, index) => {
                                                                                    let data = getScanService(website, ScanConstants.SERVICES.listing);
                                                                                    if (data !== null) {
                                                                                        if (data?.jobStatus === JobConstants.completed) {
                                                                                            if (data.status === ScanConstants.WEBSITE_STATUS.invalidFound) {
                                                                                                return <ListingWebsiteBox onMouseEnter={(e) => showListingDetailsBox({
                                                                                                    data, website: website?.website
                                                                                                }, e)} onMouseLeave={() => hideListingDetailsBox(data)} service={ScanConstants.SERVICES.listing} website={website?.website} key={index} listing={data} />
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Slider>
                                                                    </div>
                                                                    <div className='bg-light p-2 bb'><p className='px-4 m-0'>Found with Accurate Information</p></div>
                                                                    <div className='slick web-slick bb'>
                                                                        <Slider className='py-2' {...settings}>
                                                                            {
                                                                                reportData.websites.map((website, index) => {
                                                                                    let data = getScanService(website, ScanConstants.SERVICES.listing);
                                                                                    if (data !== null) {
                                                                                        if (data?.jobStatus === JobConstants.completed) {
                                                                                            if (data.status === ScanConstants.WEBSITE_STATUS.exactFound) {
                                                                                                return <ListingWebsiteBox onMouseEnter={(e) => showListingDetailsBox({
                                                                                                    data, website: website?.website
                                                                                                }, e)} onMouseLeave={() => hideListingDetailsBox(data)} service={ScanConstants.SERVICES.listing} website={website?.website} key={index} listing={data} />
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Slider>
                                                                    </div>
                                                                    <div className='bg-light d-none p-2 lightred bb'><p className='px-4 m-0'>Not Found</p></div>
                                                                    <div className='slick d-none web-slick bb'>
                                                                        <Slider className='py-2' {...settings}>
                                                                            {
                                                                                reportData.websites.map((website, index) => {
                                                                                    let data = getScanService(website, ScanConstants.SERVICES.listing);
                                                                                    if (data !== null) {
                                                                                        if (data?.jobStatus === JobConstants.completed) {
                                                                                            if (data.status === ScanConstants.WEBSITE_STATUS.notFound) {
                                                                                                return <ListingWebsiteBox onMouseEnter={(e) => showListingDetailsBox({
                                                                                                    data, website: website?.website
                                                                                                }, e)} onMouseLeave={() => hideListingDetailsBox(data)} service={ScanConstants.SERVICES.listing} website={website?.website} key={index} listing={data} />
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        </Slider>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='shadow wh mt-3 borderround'>

                                                <Row className='d-flex justify-content-center p-3 px-4 wrp-shadow'>
                                                    <Col md={8} className='p-2'>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='ic-radius lightorange me-2'><BsVolumeUp size={16} /></span> &nbsp;
                                                            <h5 className='m-0 w-100'>
                                                                <div className='txt-with-loader'>
                                                                    <span className='title'>Voice Search optimization score</span>
                                                                    {
                                                                        reportData?.overall?.jobStatus?.voice === JobConstants.pending ? <>
                                                                            <ContentLoader />
                                                                        </> : null
                                                                    }
                                                                </div>
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <p className='my-3'>See how well your business information is optimized for voice-based search queries, ensuring your online presence is geared towards voice-assisted searches, increasing visibility and engagement with local customers.</p>
                                                        </div>
                                                    </Col>
                                                    <Col md={4} className='d-flex justify-content-md-end justify-content-center'>
                                                        <div className={`small_rounded_score ${getClassNameFromGrade(reportData?.voice?.grade?.label)}`}>
                                                            <h1>{reportData?.voice?.grade?.label}</h1>
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Row className='p-4'>
                                                            <Col md={6} lg={4}>
                                                                <div className='yellow_round_box'>
                                                                    <PieChart width={500} height={500}>
                                                                        <Pie
                                                                            dataKey="value"
                                                                            isAnimationActive={true}
                                                                            data={[
                                                                                { name: 'Found', value: parseFloat(reportData?.voice?.foundPercentage), fill: getColorAsPerGrade(reportData?.voice?.grade) },
                                                                                { name: 'Not Found', value: (100 - parseFloat(reportData?.voice?.foundPercentage)), fill: '#dfdfdf' },
                                                                            ]}
                                                                            cx="50%"
                                                                            cy="50%"
                                                                            outerRadius={120}
                                                                            innerRadius={80}
                                                                            fill="#dfdfdf"
                                                                        />
                                                                        <Tooltip />
                                                                    </PieChart>
                                                                    <div className='inside_chart mt-3'>
                                                                        <h1 className='chart_txt'>{reportData?.voice?.foundPercentage}%</h1>
                                                                        <p className='text-center'>Accuracy</p>
                                                                    </div>
                                                                </div>
                                                                <p className='mt-5 ms-4'>As on {reportData?.voice?.totalAvailable} directories available</p>
                                                            </Col>

                                                            <Col md={5} lg={5}>
                                                                <Table responsive className='table-custom nowrp'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Review Source</th>
                                                                            <th>Presence</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            reportData.websites.map((website, index) => {
                                                                                let data = getScanService(website, ScanConstants.SERVICES.voice);
                                                                                if (data !== null) {
                                                                                    if (data?.jobStatus === JobConstants.completed) {
                                                                                        return <>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <WebsiteLogo website={website?.website} service={ScanConstants.SERVICES.voice} />
                                                                                                </td>
                                                                                                <td className='justify-content-center d-flex '>
                                                                                                    {
                                                                                                        data.available.toString() === "true" ? <>
                                                                                                            <div className='lightgreen voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCheck /> <p className='m-0'>Found</p></small></div>
                                                                                                        </> : <>
                                                                                                            <div className='lightred voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCross2 /> <p className='m-0'>Not Found</p></small></div>
                                                                                                        </>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        </>
                                                                                    }
                                                                                }
                                                                            })
                                                                        }

                                                                        <tr>
                                                                            <td>
                                                                                <a href="https://maps.google.com" target="_blank" className="website-icon voice">
                                                                                    <div className="img-box" style={{ backgroundImage: "url('/images/vendor/amazon-alexa.png')" }}></div>
                                                                                    <span>Amazon Alexa</span>
                                                                                </a>
                                                                            </td>
                                                                            <td className="justify-content-center d-flex ">
                                                                                {
                                                                                    parseFloat(reportData?.voice?.foundPercentage) === 100 ? <>
                                                                                        <div className='lightgreen voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCheck /> <p className='m-0'>Found</p></small></div>
                                                                                    </> : <>
                                                                                        {
                                                                                            parseFloat(reportData?.voice?.foundPercentage) === 50 ? <>
                                                                                                <div className='lightgreen voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCheck /> <p className='m-0'>Found</p></small></div>
                                                                                            </> : <>
                                                                                                <div className='lightred voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCross2 /> <p className='m-0'>Not Found</p></small></div>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>
                                                                                <a href="https://maps.google.com" target="_blank" className="website-icon voice">
                                                                                    <div className="img-box" style={{ backgroundImage: "url('/images/vendor/siri-apple.png')" }}></div>
                                                                                    <span>Apple Siri</span>
                                                                                </a>
                                                                            </td>
                                                                            <td className="justify-content-center d-flex ">
                                                                                {
                                                                                    parseFloat(reportData?.voice?.foundPercentage) === 100 ? <>
                                                                                        <div className='lightgreen voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCheck /> <p className='m-0'>Found</p></small></div>
                                                                                    </> : <>
                                                                                        {
                                                                                            parseFloat(reportData?.voice?.foundPercentage) === 50 ? <>
                                                                                                <div className='lightred voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCross2 /> <p className='m-0'>Not Found</p></small></div>
                                                                                            </> : <>
                                                                                                <div className='lightred voice-bx text-center pdd-3 px-2 '><small className='d-flex align-items-center'><RxCross2 /> <p className='m-0'>Not Found</p></small></div>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </Table>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className='shadow wh borderround p-4 mt-3 wrp-shadow'>
                                            <div className='row mb-4'>
                                                <div className='col-md-8 text'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='ic-radius lightyellow me-2'><AiOutlineUserSwitch size={16} /></span> &nbsp;
                                                        <h5 className='m-0 w-100'>
                                                            <div className='txt-with-loader'>
                                                                <span className='title'>Reviews visibility score</span>
                                                                {
                                                                    reportData?.overall?.jobStatus?.review === JobConstants.pending || reportData?.overall?.jobStatus?.datashake === JobConstants.pending ? <>
                                                                        <ContentLoader />
                                                                    </> : null
                                                                }
                                                            </div>
                                                        </h5>
                                                    </div>
                                                    <p className='my-3'>Keep track of your brand's reputation among customers who leave reviews. Monitor your ratings and reviews from multiple sources, including Google,
                                                        Facebook, and other prominent review sites.</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='d-flex justify-content-md-end justify-content-center mt-md-0 mt-3'>
                                                        <div className={`small_rounded_score ${getClassNameFromGrade(reportData?.review?.grade?.label)}`}>
                                                            <h1>{reportData?.review?.grade?.label}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='wrp-sr d-flex justify-content-between'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><AiOutlineStar size={25} color='purple' /> &nbsp; Total Reviews</h6>
                                                        </div>
                                                        <h3 className='m-0'>{printNumber(reportData?.review?.totalReviews)}</h3>
                                                    </div>
                                                    <div className='wrp-sr d-flex justify-content-between mt-3'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><AiOutlineUserAdd size={25} color='blue' /> &nbsp; Average Review Rating</h6>
                                                        </div>
                                                        <h3 className='m-0'>{reportData?.review?.averageReviewRating}/5</h3>
                                                    </div>
                                                    <div className='wrp-sr d-flex justify-content-between mt-3'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><BsPinMap size={25} color='aqua' />&nbsp; Review Sources</h6>
                                                        </div>

                                                        <h3 className='m-0'>{printNumber(reportData?.review?.totalReviewSources)}</h3>
                                                    </div>
                                                    <div className='wrp-sr d-flex justify-content-between mt-3'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><BiUserX size={25} color='red' />&nbsp; Review not responded</h6>
                                                        </div>
                                                        <h3 className='m-0'>{haveValue(reportData?.review?.totalReviewsNotResponded) ? printNumber(reportData?.review?.totalReviewsNotResponded) : '-'}</h3>
                                                    </div>
                                                </div>
                                                <div className='col-md-8 ps-md-5 ps-0'>
                                                    <Table responsive className='table-custom nowrp table-center'>
                                                        <thead>
                                                            <tr>
                                                                <th>Review source</th>
                                                                <th>Total Reviews</th>
                                                                <th>Average Review Rating</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                reportData.websites.map((website, index) => {
                                                                    let data = getScanService(website, ScanConstants.SERVICES.review);
                                                                    if (data !== null) {
                                                                        if (
                                                                            data?.jobStatus === JobConstants.completed ||
                                                                            data?.jobStatus === JobConstants.partiallyCompleted
                                                                        ) {
                                                                            return <>
                                                                                <tr className='h-52-v-align'>
                                                                                    <td>
                                                                                        <WebsiteLogo website={website?.website} service={ScanConstants.SERVICES.review} />
                                                                                    </td>
                                                                                    <td>{haveValue(data?.totalRating) ? printNumber(data?.totalRating) : '-'}</td>
                                                                                    <td>{data?.averageRating ?? '-'}</td>
                                                                                </tr>
                                                                            </>
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {
                                                    haveValue(reportData?.review?.negativeReviews) && reportData?.review?.negativeReviews.length > 0 ? <>
                                                        <div className='col-md-12 mt-4'>
                                                            <Table responsive className='table-custom reviews-table t-l nowrp table-center'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>User</th>
                                                                        <th>Review</th>
                                                                        <th>Rating</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        reportData?.review?.negativeReviews.map((review, index) => {
                                                                            return <>
                                                                                <tr className='h-52-v-align'>
                                                                                    <td><div className='user_img_negative'><img src={review?.authorPhoto} /> <p className='m-0'>{review?.authorName}</p></div></td>
                                                                                    <td className='review'><p className='m-0'>{review?.review}</p></td>
                                                                                    <td><StarReview value={review?.rating} /></td>
                                                                                </tr>
                                                                            </>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>
                                        </div>
                                        <div className='shadow wh borderround p-4 mt-3 wrp-shadow'>
                                            <div className='row mb-4'>
                                                <div className='col-md-8 text'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='ic-radius lightaqua me-2'><BsFillEyeFill size={16} /></span> &nbsp;
                                                        <h5 className='m-0 w-100'>
                                                            <div className='txt-with-loader'>
                                                                <span className='title'>Social visibility score</span>
                                                                {
                                                                    reportData?.overall?.jobStatus?.social === JobConstants.pending ? <>
                                                                        <ContentLoader />
                                                                    </> : null
                                                                }
                                                            </div>
                                                        </h5>
                                                    </div>
                                                    <p className='my-3'>Get a birds-eye view of your brand's social media performance on prominent social media platforms. Use these insights to make data-driven
                                                        decisions and improve your social media strategy.</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='d-flex justify-content-md-end justify-content-center mt-md-0 mt-3'>
                                                        <div className={`small_rounded_score ${getClassNameFromGrade(reportData?.social?.grade?.label)}`}>
                                                            <h1>{reportData?.social?.grade?.label}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='wrp-sr d-flex justify-content-between'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><AiOutlineUser color='blue' size={25} /> &nbsp;Total Following</h6>
                                                        </div>
                                                        <h3 className='m-0'>{printNumber(reportData?.social?.totalFollowers)}</h3>
                                                    </div>
                                                    <div className='wrp-sr d-flex justify-content-between mt-3'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'> <AiOutlineHeart color='red' size={25} /> &nbsp;Total Engagement</h6>
                                                        </div>
                                                        <h3 className='m-0'>{printNumber(reportData?.social?.totalEngagement)}</h3>
                                                    </div>
                                                    <div className='wrp-sr d-flex justify-content-between mt-3'>
                                                        <div className='sr-t'>
                                                            <h6 className='m-0'><AiFillCopy color='golden' size={25} /> &nbsp;Total Posts</h6>
                                                        </div>
                                                        <h3 className='m-0'>{printNumber(reportData?.social?.totalPosts)}</h3>
                                                    </div>
                                                </div>
                                                <div className='col-md-8 ps-md-5 ps-0 mt-2 mt-md-0'>
                                                    <Table responsive className='table-custom nowrp'>
                                                        <thead>
                                                            <tr>
                                                                <th>Listings</th>
                                                                <th>Total Following</th>
                                                                <th>Total Engagement</th>
                                                                <th>Total Posts</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                reportData.websites.map((website, index) => {
                                                                    let data = getScanService(website, ScanConstants.SERVICES.social);
                                                                    if (data !== null) {
                                                                        if (data?.jobStatus === JobConstants.completed) {
                                                                            return <>
                                                                                <tr className='h-52-v-align'>
                                                                                    <td>
                                                                                        <WebsiteLogo website={website?.website} service={ScanConstants.SERVICES.social} />
                                                                                    </td>
                                                                                    <td>{haveValue(data?.totalFollowers) ? printNumber(data?.totalFollowers) : '-'}</td>
                                                                                    <td>{haveValue(data?.totalEngagement) ? printNumber(data?.totalEngagement) : '-'}</td>
                                                                                    <td>{haveValue(data?.totalPosts) ? printNumber(data?.totalPosts) : '-'}</td>
                                                                                </tr>
                                                                            </>
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p className="disclaimer">*Data shown is only for last 30 days</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='shadow wh borderround p-4 mt-3 wrp-shadow d-none'>
                                            <div className='row'>
                                                <div className='col-md-8 text'>
                                                    <div className='d-flex align-items-center'>
                                                        <span className='ic-radius lightgreen me-2'><BsSearch size={16} /></span> &nbsp;
                                                        <h5 className='m-0 w-100'>
                                                            <div className='txt-with-loader'>
                                                                <span className='title'>Search visibility score</span>
                                                                {
                                                                    reportData?.overall?.jobStatus?.search === JobConstants.pending ? <>
                                                                        <ContentLoader />
                                                                    </> : null
                                                                }
                                                            </div>
                                                        </h5>
                                                    </div>
                                                    <p className='my-3'>Assess prominence and relevance of your brand to those who search online for products and services like yours. Use this to optimize your SEO efforts and improve your online visibility.</p>
                                                    <div className='row pb-2 pd-md-5'>
                                                        <div className='col-md-6'>
                                                            <div className='wrp-sr d-flex justify-content-around flex-cloumn flex-md-row'>
                                                                <div className='sr-t'>
                                                                    <h6 className='m-0 d-flex align-items-center col-space'><AiOutlineRise size={22} color='blue' /> Organic Search Ranking <BsInfoCircle /></h6>
                                                                </div>
                                                                <h3 className='m-0'>
                                                                    {
                                                                        reportData?.search?.rank !== null ? <>
                                                                            {reportData?.search?.rank > 100 ? '100+' : reportData?.search?.rank}
                                                                        </> : '-'
                                                                    }
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6 mt-3 mt-md-0 '>
                                                            <div className='wrp-sr d-flex justify-content-around flex-cloumn borderround flex-md-row'>
                                                                <div className='sr-t'>
                                                                    <h6 className='m-0 d-flex align-items-center col-space'><BsGlobe size={22} color='gold' /> Website Web Vital Score</h6>
                                                                </div>
                                                                <h3 className='m-0'>{reportData?.search?.webVitalScore ?? '-'}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='d-flex justify-content-md-end justify-content-center mt-md-0 mt-3'>
                                                        <div className={`small_rounded_score ${getClassNameFromGrade(reportData?.search?.grade?.label)}`}>
                                                            <h1>{reportData?.search?.grade?.label}</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </> : <>
                                        <p className='form-noresults py-4'>No Report Found.</p>
                                    </>
                                }
                            </>
                        }
                    </> : <>
                        <p className='form-noresults py-4'>No Report Found</p>
                    </>
                }
            </div>
        </>
    )
}

export default Step3;